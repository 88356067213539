<template>
    <div class="warp">
        <img class="left_banner" src="../../assets/imgs/chacter/chacter1.png" />
        <img class="logo" src="../../assets/imgs/no_icon_logo.png" />
        <div class="form">
            <p class="form_title">2021 WeAcg Coser参展报名表</p>
            <p class="form_des">
                本问卷是2021
                WeAcg漫展的Coser报名表，请务必填写准确的个人信息，我们将根据报名资料进行甄选，获得活动资格的小伙伴，将会接受到主办方的邮件通知或短信通知，小伙伴们请务必留下常用邮箱地址，并及时查收邮件哟！
            </p>
            <div class="form_cell">
                <el-form
                    class="form_content"
                    ref="form"
                    :model="form"
                    label-width="80px"
                    size="mini"
                    label-position="top"
                    :rules="rules"
                >
                    <el-form-item label="1.您的姓名:" prop="Name">
                        <el-input v-model="form.Name"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="2.身份证号:(仅报名使用，不会对外公开哦)"
                        prop="IDCard"
                    >
                        <el-input v-model="form.IDCard"></el-input>
                    </el-form-item>
                    <el-form-item label="3.性别:" prop="Gender">
                        <el-radio-group v-model="form.Gender">
                            <el-radio label="男">男</el-radio>
                            <el-radio label="女">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        label="4.请输入您的手机号码:"
                        prop="MobilePhone"
                    >
                        <el-input v-model="form.MobilePhone"></el-input>
                    </el-form-item>
                    <el-form-item label="5.QQ号:" prop="QQ">
                        <el-input v-model="form.QQ"></el-input>
                    </el-form-item>
                    <el-form-item label="6.邮箱:" prop="Email">
                        <el-input v-model="form.Email"></el-input>
                    </el-form-item>
                    <el-form-item label="7.微博ID:" prop="Weibo">
                        <el-input v-model="form.Weibo"></el-input>
                    </el-form-item>
                    <el-form-item label="8.Cos角色:" prop="COSRole">
                        <el-input v-model="form.COSRole"></el-input>
                    </el-form-item>
                    <el-form-item label="9.角色出处:" prop="RoleSource">
                        <el-input v-model="form.RoleSource"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="10.上传你的cos美照，最好是正片。(上传图片今后会用在各种宣传海报上)"
                    >
                        <el-upload
                            action="none"
                            :headers="upLoadHeaders"
                            ref="upload"
                            list-type="picture-card"
                            :file-list="imgFiles"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :on-error="onError"
                            :auto-upload="false"
                            :http-request="appendImage"
                            accept="image/jpeg,image/png,image/jpg"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('form')"
                            >立即创建</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                Name: '',
                IDCard: '',
                Gender: '',
                MobilePhone: '',
                QQ: '',
                Email: '',
                Weibo: '',
                COSRole: '',
                RoleSource: '',
                AttendanceDate: ''
            },
            upLoadHeaders: { 'Content-Type': 'multipart/form-data' },
            uploadUrl: 'http://localhost:8081/api/Img/images/Upload/Pic',
            imgFiles: [],
            fileData: null,
            dialogImageUrl: '',
            dialogVisible: false,
            rules: {
                Name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'none'
                    }
                ],
                IDCard: [
                    {
                        required: true,
                        message: '请输入身份证号',
                        trigger: 'none'
                    }
                ],
                Gender: [
                    {
                        required: true,
                        message: '请选择性别',
                        trigger: 'none'
                    }
                ],
                MobilePhone: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'none'
                    }
                ],
                Email: [
                    {
                        required: true,
                        message: '请输入邮箱',
                        trigger: 'none'
                    }
                ],
                COSRole: [
                    {
                        required: true,
                        message: '请输入要cos的角色',
                        trigger: 'none'
                    }
                ],
                RoleSource: [
                    {
                        required: true,
                        message: '请输入角色出处',
                        trigger: 'none'
                    }
                ]
            }
        }
    },
    methods: {
        onSubmit(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    if (this.doubleRuleCheck()) {
                        this.fileData = new FormData()
                        this.$refs.upload.submit()
                        try {
                            this.axios
                                .post('Img/images/Upload/Pic', this.fileData)
                                .then(res => {
                                    if (res.data.success) {
                                        var postData = {
                                            baseData: this.form,
                                            FileNames: res.data.response
                                        }
                                        this.axios
                                            .post(
                                                'Comiket/CreateForm',
                                                postData
                                            )
                                            .then(response => {
                                                this.$notify({
                                                    title: '消息',
                                                    message: response.data.msg,
                                                    position: 'top-right'
                                                })
                                            })
                                            .catch(() => {
                                                this.$notify({
                                                    title: '消息',
                                                    message:
                                                        '出错喽,请稍后重试或联系主办方处理',
                                                    position: 'top-right'
                                                })
                                            })
                                    }
                                })
                                .catch(() => {
                                    this.$notify({
                                        title: '消息',
                                        message:
                                            '出错喽,请稍后重试或联系主办方处理',
                                        position: 'top-right'
                                    })
                                })
                        } catch (e) {
                            this.$notify({
                                title: '消息',
                                message: '出错喽,请稍后重试或联系主办方处理',
                                position: 'top-right'
                            })
                        }
                    }
                } else {
                    return false
                }
            })
        },
        onError() {
            console.log('上传失败')
        },
        appendImage(param) {
            this.fileData.append('files', param.file) //element submit 循环插入图片
        },
        doubleRuleCheck() {
            var IdNumReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            var phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/
            if (!IdNumReg.test(this.form.IDCard)) {
                const h = this.$createElement
                this.$notify({
                    title: '身份证号验证失败喽',
                    message: h(
                        'i',
                        { style: 'color: teal' },
                        '请填写正确的身份证号'
                    )
                })
                return false
            }
            if (!phoneReg.test(this.form.MobilePhone)) {
                const h = this.$createElement
                this.$notify({
                    title: '手机号验证失败喽',
                    message: h(
                        'i',
                        { style: 'color: teal' },
                        '请填写正确的手机号'
                    )
                })
                return false
            }
            return true
        },
        handleRemove(file, fileList) {
            console.log(file, fileList)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        }
    }
}
</script>

<style lang="less" scoped>
.warp {
    width: 100%;
    height: 100%;
    color: #999;
    background: linear-gradient(to bottom, #d6a4a4, #dae2f8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
        font-family: Helvetica;
        width: 200px;
        z-index: 10;
    }
    .left_banner {
        position: fixed;
        left: -124px;
        z-index: 0;
        height: 900px;
    }
    .form {
        display: flex;
        flex-direction: column;
        max-width: 700px;
        width: 90%;
        flex: 1;
        background: linear-gradient(to bottom, #ffffff, #dae2f800);
        padding: 20px;
        border-radius: 6px;
        z-index: 1;
        .form_title {
            text-align: center;
            padding: 0 0 20px 0;
            font-size: 24px;
            font-weight: 600;
        }
        .form_des {
            text-align: left;
            padding: 0 0 20px 0;
            font-size: 14px;
        }
        .form_cell {
            flex: 1;
        }
        .form_content {
        }
    }
}
/deep/.el-input {
    width: 200px;
}
/deep/.el-form-item__label {
    color: #999 !important;
}
</style>
